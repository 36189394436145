import { getSelfLocaleName } from '../../services/getLocaleName';
import AppLocale from '../../lngProvider';

export type appLanguagesType = {
  lang: string;
  language: string;
};

export default class ShopLocaleModel {
  locale: string;
  language: string;
  defaultLanguage: string;
  allowedLanguages: string[];
  appLanguages: appLanguagesType[];
  currencyCode: string;
  countryCode: string;
  timezone: string;
  antdLocale: any;

  constructor(data: any) {
    this.locale = data.locale.replace('_', '-');
    this.defaultLanguage = data.defaultLanguage.replace('_', '-');
    this.allowedLanguages = data.allowedLanguages.map((lang: string) =>
      lang.replace('_', '-')
    );
    this.appLanguages = createAllowedLanguagesList(this.allowedLanguages);
    this.language = getLanguage(data.language, this.locale, this.appLanguages);
    this.currencyCode = data.currencyCode;
    this.countryCode = data.countryCode;
    this.timezone = data.timezone;
    this.antdLocale = null;
  }

  setAntdLocale(locale: string) {
    this.antdLocale = locale;
  }
}

const getLanguage = (
  language: string,
  locale: string,
  appLanguages: appLanguagesType[]
) => {
  if (appLanguages.some((lang) => lang.lang === language)) {
    return language;
  }
  if (appLanguages.some((lang) => lang.lang === locale)) {
    return locale;
  }
  return appLanguages[0].lang;
};

const getBestLanguage = (language: string, acceptedLanguages: string[]) => {
  let bestLanguage = null;
  let secondBestLanguage = null;

  const languageWithoutRegion = language.split(/[_-]/)[0];

  for (const locale of acceptedLanguages) {
    if (locale === language) {
      return locale;
    }

    if (locale === languageWithoutRegion) {
      bestLanguage = locale;
    }

    if (
      locale.split(/[_-]/)[0] === languageWithoutRegion &&
      secondBestLanguage === null
    ) {
      secondBestLanguage = locale;
    }
  }

  return bestLanguage ?? secondBestLanguage ?? acceptedLanguages[0];
};

const createAllowedLanguagesList = (allowedLanguages: string[]) => {
  const acceptedLanguages = Object.keys(AppLocale);
  const filteredUniqueLanguages = new Map();

  allowedLanguages.forEach((language) => {
    filteredUniqueLanguages.set(
      getBestLanguage(language, acceptedLanguages),
      language
    );
  });

  return Array.from(filteredUniqueLanguages.keys()).map((language: string) => ({
    lang: language,
    language: getSelfLocaleName(language),
  }));
};
